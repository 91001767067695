<script setup>
import { computed } from "vue";

const props = defineProps({
  arrow: Boolean,
  severity: {
    type: String,
    default: "primary",
  },
  url: String,
  buttonClass: String,
  border: {
    type: Boolean,
    default: false,
  },
});

const buttonDarkClasses = computed(() => {
  let classes = [];

  if (props.severity === "primary") {
    classes.push("bg-white", "text-black");

    if (props.border) {
      classes.push("border border-black");
    }
  } else if (props.severity === "secondary") {
    classes.push("bg-black", "text-white");

    if (props.border) {
      classes.push("border border-white");
    }
  } else if (props.severity === "tertiary") {
    classes.push("bg-jade", "text-black");
  } else if (props.severity === "outline") {
    classes.push("bg-transparent", "text-black");
    if (props.border) {
      classes.push("border border-black");
    }
  }

  return classes.join(" ");
});
</script>

<template>
  <NuxtLink
    :to="url"
    v-bind="$attrs"
    class="common btn duration-300 transition font-medium px-8 py-3 rounded-full leading-none text-base border-solid inline-flex items-center"
    :class="`${buttonDarkClasses} ${buttonClass}`"
  >
    <slot />
    <span
      v-if="arrow"
      class="text-black inline-block ms-2.5"
    >
      <svg-icon name="common/arrow" class="w-2.5 h-3" :class="severity === 'secondary' ? 'text-white' : 'text-black'" />
    </span>
  </NuxtLink>
</template>
